<template lang="pug">
div
  v-card(flat)
    simple-table
      thead
        tr
          td Name
          td Amount
          td Resell Partner
          td Payment Partner
          td.text-right Payment Network
      tbody
        tr(
          v-for="(item, index) in bizservices",
          :key="index",
          role="button",
          @click="edit(item.biz)"
        )
          td(:class="{ 'red--text': item.ended }") {{ item.name }}
            v-icon.ml-2(color="red", small, v-if="item.waive") mdi-cancel
          td
            span(v-if="item.amount") {{ item.amount | currency }}
          td
            span(v-if="item.sales_force") {{ getOrganization(item.sales_force.resell_partner) }}
          td
            span(v-if="item.sales_force") {{ getOrganization(item.sales_force.payment_partner) }}
          td.text-right
            span(v-if="item.sales_force") {{ item.sales_force.payment_network | payment_network }}
  CountByReseller.my-3
  SalesForceOrganizationLoad
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapGetters, mapActions } from "vuex";
import PaymentNetwork from "@/components/basic/PaymentNetwork.json";
import CountByReseller from "./CountByReseller";

export default {
  filters: {
    payment_network(value) {
      return PaymentNetwork.find((o) => o.value == value)?.text || value;
    },
  },
  components: { CountByReseller },
  computed: {
    ...mapGetters(["bizservices", "salesOrganizations", "salesPersons"]),
  },
  methods: {
    ...mapActions(["updateBizService", "setSalesPersons"]),
    edit(bizId) {
      EventBus.$emit("show-edit-service", bizId);
    },
    getOrganization(val) {
      const found = this.salesOrganizations.find((o) => o._id == val);
      return found?.name || "";
    },
    async populateBiz(item) {
      const params = { bizId: item.biz };
      const result = await this.$api.service.account.custom(
        "populateBiz",
        params
      );
      this.updateBizService(result);
    },
    async endService(item) {
      const params = { id: item._id, ended: Date.now() };
      const result = await this.$api.service.account.custom(
        "endService",
        params
      );
      this.updateBizService(result);
    },
    async startService(item) {
      const params = { id: item._id, ended: 0 };
      const result = await this.$api.service.account.custom(
        "endService",
        params
      );
      this.updateBizService(result);
    },
    async toggleWaive(item) {
      let value = !(item.waive == true);
      const params = {
        criteria: { _id: item._id },
        action: { $set: { waive: value } },
      };
      const result = await this.$api.service.account.update(params);
      this.updateBizService(result);
    },
    async loadPersons() {
      const params = { criteria: {} };
      try {
        const result = await this.$api.salesforce.person.list(params);
        this.setSalesPersons(result);
      } catch (e) {
        this.setSalesPersons([]);
      }
    },
  },
};
</script>
