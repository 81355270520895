<template lang="pug">
.box-container
  v-btn(icon, small, @click="previous()", title="previous")
    v-icon mdi-menu-left
  v-menu(offset-y)
    template(v-slot:activator="{ on }")
      .btn-title(v-on="on") {{ months[index] }}
    v-card(max-height="400", width="120")
      v-list.py-0(dense)
        v-list-item(
          v-for="(item, index) in months",
          :key="index",
          @click="select(index)"
        )
          v-list-item-content
            v-list-item-title {{ item }}
  v-btn(icon, small, @click="next()", title="next")
    v-icon mdi-menu-right
</template>

<script>
/**
 * Select a month from 2017/01 - Current month
 * return
 * Name: YYYY-MM
 * Range: begin (unix time), end (unix time)
 */
import moment from "moment-timezone";
import _ from "underscore";

export default {
  name: "MonthSelector",
  props: {
    startYear: {
      type: Number,
      default: 2017,
    },
  },
  data() {
    return { index: 0 };
  },
  computed: {
    months() {
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth() + 1;
      let year = this.startYear;
      let items = [];
      while (year < currentYear) {
        _.each(_.range(1, 13), (m) => items.push(this.format(year, m)));
        year++;
      }
      let month = 1;
      while (month <= currentMonth) {
        items.push(this.format(currentYear, month));
        month++;
      }
      items.reverse();
      return items;
    },
  },
  mounted() {
    this.select(0);
  },
  methods: {
    format(year, month) {
      return year + "-" + String(month).padStart(2, "0");
    },
    previous() {
      if (this.index + 1 < this.months.length) {
        this.index += 1;
        this.select(this.index);
      }
    },
    next() {
      if (this.index != 0) {
        this.index -= 1;
        this.select(this.index);
      }
    },
    select(index) {
      this.index = index;
      const month = this.months[index];
      const begin = moment(month, "YYYY-MM").startOf("month").valueOf();
      const end = moment(month, "YYYY-MM").endOf("month").valueOf();
      const range = { begin, end };
      this.$emit("select", {
        name: month, // format: YYYY-MM
        range, // { begin, end }
        month, // format: YYYY-MM
        begin, // unix time
        end, // unix time
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.box-container
  display: inline-flex
  flex-direction: row
  align-items: center
  border-radius: 6px
  border: 1px solid #e4e4e4
  background-color: white
</style>