<template lang="pug">
v-dialog(v-model="dialog", width="300", scrollable)
  template(v-slot:activator="{ on }")
    IconBtn(v-on="on", @click="open")
  v-card
    v-card-title Business Logo
    v-card-text
      v-col(align="center")
        croppa(
          v-model="myCroppa",
          :quality="2",
          :canvas-color="canvas_color",
          initial-size="contain"
        )
        .caption Use mouse to move and zoom.
        v-checkbox(
          v-model="show_custom",
          color="secondary",
          label="Custom background color"
        )
        div(v-if="show_custom")
          v-divider
          v-color-picker(
            v-model="canvas_color",
            hide-mode-switch,
            hide-input,
            hide-canvas,
            flat,
            show-swatches,
            mode="hexa"
          )
    v-card-actions
      v-btn(
        @click="generateBlob",
        block,
        color="secondary",
        :loading="loading"
      ) Save
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      myCroppa: null,
      loading: false,
      dialog: false,
      show_custom: false,
      canvas_color: "#FFFFFF", // background color
    };
  },
  computed: { ...mapGetters(["biz"]) },
  methods: {
    open() {
      this.show_custom = false;
      this.canvas_color = "#FFFFFF";
      this.myCroppa?.remove();
    },
    async generateBlob() {
      this.myCroppa.generateBlob(
        (blob) => {
          if (!blob) {
            this.myCroppa.chooseFile();
          } else {
            this.save(blob);
          }
        },
        "image/jpeg",
        0.8
      );
    },
    async save(blob) {
      if (!this.biz?._id) return;
      this.loading = true;
      const formData = new FormData();
      formData.append("file", blob);
      formData.append("bizId", this.biz._id);
      try {
        const result = await this.$api.b.action.custom("updateLogo", formData);
        this.$store.dispatch("setBiz", result);
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>