<template lang="pug">
div
  IconBtn(icon="mdi-pencil", @click="open()", title="")
  v-dialog(v-model="dialog", width="500")
    v-card
      v-toolbar(flat, dense)
        .subtitle-2 Edit Business Name
      v-card-text
        v-form(@submit.prevent="save")
          v-text-field(v-model="name", label="Name")
          v-text-field(v-model="short_name", label="Chain Short Name")
          v-btn(type="submit", block, :disabled="!name", color="secondary") Save
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: ["biz"],
  data() {
    return {
      dialog: false,
      name: "",
      short_name: "",
      loading: false,
    };
  },
  methods: {
    ...mapActions(["setBiz"]),
    open() {
      if (!this.biz) return;
      this.name = this.biz.name || "";
      this.short_name = this.biz.short_name || "";
      this.dialog = true;
      this.loading = false;
    },
    async save() {
      if (!this.biz || !this.name) return;
      this.loading = true;
      const params = {
        criteria: { _id: this.biz._id },
        action: {
          $set: {
            name: this.name.trim(),
            short_name: this.short_name.trim(),
          },
        },
      };
      const result = await this.$api.biz.update(params);
      this.setBiz(result);
      this.loading = false;
      this.dialog = false;
    },
  },
};
</script>
