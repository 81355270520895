<template lang="pug">
v-card(flat, :loading="loading")
  simple-table
    tbody
      tr(v-for="(item, index) in items", :key="index")
        th {{ item.name }}
        td.text-right {{ item.value }}
    tfoot
      tr
        th Total
        th.text-right {{ total }}
</template>

<script>
export default {
  data() {
    return { summary: null, loading: false };
  },
  computed: {
    total() {
      return this.summary?.total || 0;
    },
    items() {
      return this.summary?.items || [];
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      this.loading = true;
      try {
        this.summary = await this.$api.biz.custom("stats/countByStatus");
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
