<template lang="pug">
v-dialog(v-model="dialog", width="400")
  template(v-slot:activator="{ on }")
    v-btn(icon, small, v-on="on")
      v-icon(color="secondary") mdi-dots-horizontal
  v-card
    v-card-title Actions
    v-card-text
      .s-vstack
        TurnOnOnline
        TurnOnPOS
        ClosePermanent
        Dummy
        ToggleNoOnline
</template>

<script>
import ClosePermanent from "./ClosePermanent";
import Dummy from "./Dummy";
import ToggleNoOnline from "./ToggleNoOnline";
import TurnOnOnline from "./TurnOnOnline";
import TurnOnPOS from "./TurnOnPOS";

export default {
  components: {
    TurnOnOnline,
    TurnOnPOS,
    Dummy,
    ClosePermanent,
    ToggleNoOnline,
  },
  data() {
    return { dialog: false };
  },
};
</script>
