<template lang="pug">
v-dialog(v-model="dialog", width="300")
  template(v-slot:activator="{ on }")
    v-btn.mr-3(@click="open()", small, depressed, color="secondary") Edit Service
  v-card(v-if="biz")
    v-toolbar(flat, dense)
      .subtitle-2 {{ biz.name }}
    v-divider
    v-card-text
      span Do you want to start a service account for this business?
      v-row
        v-col
          v-btn(block, text, @click="dialog = false") Cancel
        v-col
          v-btn(
            block,
            color="secondary",
            :loading="loading",
            @click="submit()"
          ) Send
</template>

<script>
import { EventBus } from "@/event-bus.js";

export default {
  props: ["biz"],
  data() {
    return {
      dialog: false,
      service: null,
      loading: false,
    };
  },
  methods: {
    async open() {
      if (!this.biz) return;
      const bizId = this.biz._id;
      const params = { criteria: { biz: bizId } };
      try {
        const result = await this.$api.service.account.retrieve(params);
        if (result) {
          EventBus.$emit("show-edit-service", bizId);
          return;
        }
      } catch (err) {
        //
      }
      this.dialog = true;
    },
    async submit() {
      if (!this.biz) return;
      this.loading = true;
      const data = {
        biz: this.biz._id,
        name: this.biz.name,
        address: this.biz.address,
      };
      try {
        await this.$api.service.account.create(data);
        this.$toast.success("Service account created");
        this.dialog = false;
      } catch (err) {
        this.$toast.error(err.response.data);
      }
      this.loading = false;
    },
  },
};
</script>
