<template lang="pug">
v-dialog(v-model="dialog", width="600", scrollable)
  v-form(@submit.prevent="save")
    v-card(v-if="service")
      v-toolbar(dense, flat)
        .subtitle-2 Edit POS Service
        v-spacer
        Populate(:service="service")
      v-divider
      v-card-text.pt-3
        v-row(dense)
          v-col(cols="4")
            v-switch(
              color="secondary",
              v-model="service.pos.status",
              label="POS",
              ripple=false
            )
          v-col(cols="4")
            v-select(
              v-model="service.member",
              label="Member",
              :items="memberLevels"
            )
          v-col(cols="4")
            v-select(
              v-model="service.preferred_payment",
              label="Payment",
              :items="payment_items"
            )
        v-row(dense)
          v-col(cols="4")
            v-switch(
              color="secondary",
              v-model="service.ccm.status",
              label="Credit Card Machine",
              ripple=false
            )
          v-col(cols="4", v-if="service.ccm.status")
            v-select(
              v-model="service.ccm.quantity",
              label="Quantity",
              :items="quantities"
            )
        v-row(dense)
          v-col(cols="4")
            v-switch(
              color="secondary",
              v-model="service.third_party.status",
              label="Chowly",
              ripple=false
            )
          v-col(cols="8", v-if="service.third_party.status")
            v-row(dense)
              v-col(cols="9")
                v-text-field(
                  v-model="service.third_party.chowly_id",
                  label="Chowly ID",
                  hint="API Key: e4217764-e89f-4188...",
                  persistent-hint
                )
              v-col(cols="3")
                v-text-field(
                  v-model="service.third_party.store_id",
                  label="Chowly Partner ID",
                  hint="Store: 37674",
                  persistent-hint
                )
            v-text-field(
              v-model="service.third_party.test_url",
              label="Test URL",
              hide-details
            )
            v-switch(
              color="secondary",
              v-model="service.third_party.merge_menu",
              label="Combine Menus into One",
              ripple=false,
              hide-details
            )
        v-row(dense, wrap)
          v-col(cols="4")
            v-switch(
              color="secondary",
              v-model="service.kds",
              label="Kitchen Display",
              ripple=false,
              hide-details
            )
          v-col(cols="4")
            v-switch(
              color="secondary",
              v-model="service.inventory",
              label="Inventory",
              ripple=false,
              hide-details
            )
          v-col(cols="4")
            v-switch(
              color="secondary",
              v-model="service.qbk",
              label="Quickbooks",
              ripple=false,
              hide-details
            )
          v-col(cols="4")
            v-switch(
              color="secondary",
              v-model="service.rsv",
              label="Reservation",
              ripple=false,
              hide-details
            )
          v-col(cols="4")
            v-switch(
              color="secondary",
              v-model="service.dt",
              label="Drive Thru",
              ripple=false,
              hide-details
            )
          v-col(cols="4")
            v-switch(
              color="secondary",
              v-model="service.ff",
              label="Front Facing",
              ripple=false,
              hide-details
            )
        v-row(dense, wrap)
          v-col(cols="6")
            v-text-field(
              v-model="service.discount.amount",
              label="Discount Amount",
              hide-details
            )
          v-col(cols="6")
            v-text-field(
              v-model="service.discount.reason",
              label="Discount Reason",
              hide-details
            )
        v-row(v-if="service.custom", dense, wrap)
          v-col(cols="6")
            v-text-field(
              v-model="service.custom.amount",
              label="Custom Amount",
              hide-details
            )
          v-col(cols="6")
            v-text-field(
              v-model="service.custom.reason",
              label="Custom Reason",
              hide-details
            )
        v-row(wrap, dense)
          v-col(cols="6")
            v-select(
              v-model="service.sales_force.resell_partner",
              :items="organizations",
              label="Resell Partner",
              hide-details
            )
          v-col(cols="6")
            v-select(
              v-model="service.sales_force.resell_person",
              :items="persons",
              label="Resell Person",
              hide-details
            )
          v-col(cols="6")
            v-select(
              v-model="service.sales_force.payment_partner",
              :items="organizations",
              label="Payment Partner",
              hide-details
            )
          v-col(cols="6")
            v-select(
              v-model="service.sales_force.payment_person",
              :items="persons",
              label="Payment Person",
              hide-details
            )
          v-col(cols="6")
            v-select(
              v-model="service.sales_force.payment_network",
              :items="paymentNetwork",
              label="Payment Network",
              hide-details
            )
      v-card-actions
        v-btn(block, color="secondary", type="submit", :loading="loading") Save
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapActions, mapGetters } from "vuex";
import PaymentNetwork from "@/components/basic/PaymentNetwork.json";
import Populate from "./Populate";

export default {
  components: { Populate },
  data() {
    return {
      dialog: false,
      loading: false,
      service: null,
      quantities: [0, 1, 2, 3],
      memberLevels: [
        { text: "Inactive", value: 0 },
        { text: "Basic", value: 1 },
        { text: "Advanced", value: 2 },
      ],
      payment_items: [
        { text: "Stripe Balance", value: "stripe_balance" },
        { text: "Credit Card", value: "credit_card" },
      ],
      paymentNetwork: PaymentNetwork,
    };
  },
  computed: {
    ...mapGetters(["salesOrganizations", "salesPersons"]),
    persons() {
      return this.salesPersons?.map((o) => {
        return { text: o.name, value: o._id };
      });
    },
    organizations() {
      return this.salesOrganizations?.map((o) => {
        return { text: o.name, value: o._id };
      });
    },
  },
  mounted() {
    EventBus.$on("show-edit-service", this.open);
  },
  destroyed() {
    EventBus.$off("show-edit-service", this.open);
  },
  methods: {
    ...mapActions(["updateBizService", "addBizService"]),
    async load(biz) {
      if (!biz) return;
      const params = { criteria: { biz: biz } };
      try {
        const result = await this.$api.service.account.retrieve(params);
        if (result) {
          this.service = result;
        } else {
          this.loadFresh(biz);
        }
      } catch (err) {
        this.loadFresh(biz);
      }
      if (!this.service.sales_force) {
        this.service.sales_force = { person: null, organization: null };
      }
    },
    loadFresh(biz) {
      if (!biz) return;
      this.service = {
        biz: biz,
        pos: { status: false },
        ccm: { status: false, quantity: 0 },
        third_party: {
          status: false,
          chowly_id: "",
          store_id: "",
          test_url: "",
          merge_menu: false,
        },
        kds: false,
        inventory: false,
        qbk: false,
        member: 0,
        discount: { amount: 0, reason: "" },
        rsv: false,
        dt: false,
        ff: false,
        sales_force: { person: null, organization: null },
      };
    },
    open(data) {
      if (!data) return;
      this.service = null;
      this.load(data);
      this.dialog = true;
    },
    async save() {
      delete this.service.name;
      delete this.service.address;
      if (this.service.discount && this.service.discount.amount < 0) {
        return this.$toast.error("Discount amount should be positive");
      } else if (this.service.discount.amount > 0) {
        if (!this.service.discount.reason) {
          return this.$toast.error("Please provide a discount reason");
        }
      }
      this.loading = true;
      try {
        if (this.service?._id) {
          const criteria = { _id: this.service._id };
          const action = { $set: this.service };
          const result = await this.$api.service.account.update({
            criteria,
            action,
          });
          this.updateBizService(result);
        } else {
          const result = await this.$api.service.account.create(this.service);
          this.addBizService(result);
        }
        this.dialog = false;
      } catch (err) {
        return this.$toast.error(err.response?.data || err.message);
      }
      this.loading = false;
    },
  },
};
</script>
