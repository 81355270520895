<template lang="pug">
v-card(flat, v-if="biz")
  v-toolbar(flat, dense)
    .subtitle-2 Business Logo
    v-spacer
    Form
  v-card-text
    .img-container(v-if="logo")
      v-img(:src="logo", aspect-ratio="1")
    span(v-else) Please add a logo for display.
</template>

<script>
import { mapGetters } from "vuex";
import Form from "./Form";

export default {
  components: { Form },
  computed: {
    ...mapGetters(["biz"]),
    logo() {
      if (this.biz?.logo) return process.env.VUE_APP_MEDIAHOST + this.biz.logo;
      return null;
    },
  },
};
</script>

<style lang="sass" scoped>
.img-container
  width: 120px
  height: 120px
  overflow: hidden
  margin-left: auto
  margin-right: auto
  border-radius: 6px
  border: 1px solid #ccc
</style>