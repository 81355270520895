<template lang="pug">
div
  v-list-item(@click="toggle")
    v-list-item-content
      v-list-item-title
        span Chowly Integration
    v-list-item-action
      v-progress-circular(v-if="loading", size="12", color="secondary")
      v-icon(small, :color="color", v-else) {{ icon }}
  Detail(:item="item", v-if="value", @open="open")
  Form(ref="form")
</template>

<script>
import { mapActions } from "vuex";
import Detail from "./Detail";
import Form from "./Form";

export default {
  components: { Detail, Form },
  props: {
    item: { type: Object, required: true },
  },
  data() {
    return { loading: false };
  },
  computed: {
    value() {
      return this.item.third_party.status;
    },
    color() {
      return this.value ? "success" : "gray";
    },
    icon() {
      return this.value ? "mdi-check" : "mdi-minus";
    },
  },
  methods: {
    ...mapActions(["setBizService"]),
    async toggle() {
      this.loading = true;
      const newValue = !this.value;
      const criteria = { _id: this.item._id };
      const action = { $set: { "third_party.status": newValue } };
      try {
        const result = await this.$api.service.account.update({
          criteria,
          action,
        });
        this.setBizService(result);
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
      this.loading = false;
    },
    open() {
      this.$refs.form.open(this.item);
    },
  },
};
</script>