<template lang="pug">
v-card(v-if="biz", flat)
  v-toolbar(dense, flat)
    .subtitle-2 Basic Profile
    v-spacer
    Form
  simple-table
    tbody
      tr
        th Price Level
        td.text-right {{ biz.price_range | price_level }}
      tr
        th Place Type
        td.text-right {{ biz.industry | industry }}
      tr(v-if="biz.industry < 100")
        th Categories
        td.text-right {{ biz.cates | cates }}
      tr
        th Website
        td.text-right {{ biz.website }}
      tr
        th Description
        td.text-right {{ biz.description }}
</template>

<script>
import Form from "./Form";

export default {
  components: { Form },
  props: { biz: { type: Object, required: true } },
};
</script>
