<template lang="pug">
.my-container
  .subtitle-2 Actions Logs ({{ timezone }})
  .caption Order created at: {{ order.created | datetime("smart", timezone) }}
  .caption(v-if="order.device") Device: {{ order.device?.id | truncate(8) }}, {{ order.device?.appName }} ({{ order.device?.appVersion }})
  PrintLogView(ref="printLog", :order="order")
  ActionView(ref="actionView", :order="order")
  ConversationView(ref="conversationView", :order="order")
</template>

<script>
import ActionView from "./ActionView";
import ConversationView from "./ConversationView";
import PrintLogView from "./PrintLogView";

export default {
  components: { PrintLogView, ActionView, ConversationView },
  props: {
    order: { type: Object, default: null },
  },
  computed: {
    timezone() {
      return this.order?.seller?.address?.timezone;
    },
  },
  methods: {
    async load() {
      await this.$refs.printLog.load();
      await this.$refs.actionView.load();
      await this.$refs.conversationView.load();
    },
  },
};
</script>

<style lang="sass" scoped>
// container with vertical stack and gap of 10px
.my-container
  display: flex
  flex-direction: column
  gap: 10px
</style>