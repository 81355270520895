<template lang="pug">
div(v-if="order")
  .caption
    span.mr-2 ID:
    ClickToCopy(:text="order._id")
  .caption Proxy: {{ order.proxy }}
  .caption Device ID: {{ deviceInfo }}
  .my-3
    PartialRefund.mr-2(:order="order")
    AdjustPrice.mr-2(:order="order")
    TaxExempt.mr-2(:order="order")
  .my-3
    CloseOrder.mr-2(:order="order")
    CancelOrder.mr-2(:order="order")
    v-menu(offset-y)
      template(v-slot:activator="{ on }")
        v-btn(icon, small, v-on.stop="on")
          v-icon(color="secondary") mdi-dots-horizontal
      v-list.py-0(dense)
        EditTime(:order="order")
        EditTip(:order="order")
</template>

<script>
import { mapGetters } from "vuex";
import CloseOrder from "./CloseOrder";
import PartialRefund from "./PartialRefund";
import AdjustPrice from "./AdjustPrice";
import TaxExempt from "./TaxExempt";
import CancelOrder from "./CancelOrder";
import EditTime from "./EditTime";
import EditTip from "./EditTip";

export default {
  components: {
    CloseOrder,
    PartialRefund,
    AdjustPrice,
    TaxExempt,
    CancelOrder,
    EditTime,
    EditTip,
  },
  computed: {
    ...mapGetters(["order"]),
    deviceInfo() {
      let items = [
        this.order?.device?.id,
        this.order?.device?.model,
        this.order?.device?.appName,
        this.order?.device?.appVersion,
      ];
      return items.filter((item) => item).join(", ");
    },
  },
};
</script>

