<template lang="pug">
v-dialog(v-model="dialog", width="600")
  v-card
    v-card-title Chowly Settings
    v-form(@submit.prevent="submit")
      v-card-text
        v-text-field(
          v-model="chowly_id",
          label="API Key",
          hint="e4217764-e89f-4188...",
          persistent-hint
        )
        v-text-field(
          v-model="store_id",
          label="Store ID",
          hint="37674",
          persistent-hint
        )
        v-text-field(v-model="test_url", label="Test URL")
        v-switch(
          color="secondary",
          v-model="merge_menu",
          label="Combine Menus into One",
          ripple=false
        )
      v-card-actions
        v-btn(type="submit", color="secondary", :loading="loading", block) Save
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      item: null,
      loading: false,
      dialog: false,
      chowly_id: "",
      store_id: "",
      test_url: "",
      merge_menu: false,
    };
  },
  methods: {
    ...mapActions(["setBizService"]),
    async submit() {
      if (!this.chowly_id) return this.$toast.error("API Key is required");
      if (!this.store_id) return this.$toast.error("Store ID is required");

      this.loading = true;
      const criteria = { _id: this.item._id };
      const action = {
        $set: {
          "third_party.status": true,
          "third_party.chowly_id": this.chowly_id,
          "third_party.store_id": this.store_id,
          "third_party.test_url": this.test_url,
          "third_party.merge_menu": this.merge_menu,
        },
      };
      try {
        const result = await this.$api.service.account.update({
          criteria,
          action,
        });
        this.setBizService(result);
        this.dialog = false;
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
      this.loading = false;
    },
    open(item) {
      if (!item) return;
      this.item = item;
      this.chowly_id = item.third_party.chowly_id;
      this.store_id = item.third_party.store_id;
      this.test_url = item.third_party.test_url;
      this.merge_menu = item.third_party.merge_menu;
      this.dialog = true;
      this.loading = false;
    },
  },
};
</script>