<template lang="pug">
v-dialog(v-model="dialog", width="500", scrollable)
  v-form(@submit.prevent="submit")
    v-card
      v-toolbar(flat, dense)
        .subtitle-2 {{ title }} Corp-Level Access
      v-card-text
        v-row(wrap, dense)
          v-col(cols="6")
            v-text-field(label="Name", v-model="name")
          v-col(cols="6")
            PhoneField(v-model="phone")
          v-col(cols="6")
            v-text-field(
              label="Email",
              v-model="email",
              :error-messages="emailErrors",
              @blur="$v.email.$touch()",
              :disabled="isOld"
            )
          v-col(cols="6")
            v-select(
              label="Access Level",
              v-model="privilege",
              :items="roles",
              :error-messages="privilegeErrors",
              @blur="$v.privilege.$touch()",
              :disabled="isMe"
            )
          v-col(cols="12")
            v-select(
              label="Country_State",
              v-model="country_states",
              :items="country_state_items",
              multiple
            )
        v-checkbox(
          v-model="access_wage",
          label="Can access wage and edit timecard",
          v-if="isManager || isCustom",
          color="secondary"
        )
        div(
          v-for="(group, gindex) in accesses_groups",
          :key="gindex + 'group'",
          v-if="isCustom"
        )
          .subtitle-2 {{ group.title }}
          v-radio-group(v-model="items", multiple, row)
            v-radio(
              v-for="(item, index) in group.items",
              :key="index + 'item'",
              :label="item.title",
              :value="item.name",
              color="secondary"
            )
      v-card-actions
        v-btn(
          block,
          color="secondary",
          type="submit",
          :disabled="$v.$invalid",
          :loading="loading"
        ) Save
</template>

<script>
import { mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import { EventBus } from "@/event-bus.js";
import AccessGroups from "@/components/basic/NavGroups.json";
import _ from "underscore";

export default {
  mixins: [validationMixin],
  validations: {
    email: { required, email },
    privilege: { required },
  },
  data() {
    return {
      menu: null,
      dialog: false,
      access: null,
      loading: false,
      email: "",
      name: "",
      phone: "",
      country_states: [],
      privilege: null,
      access_wage: false,
      items: [],
      roles: [
        { text: "Owner", value: 1 },
        { text: "Manager", value: 2 },
        { text: "POS App Only", value: 3 },
        { text: "Custom", value: 4 },
      ],
      accesses_groups: AccessGroups,
      country_state_items: [],
    };
  },
  computed: {
    isOld() {
      return this.access && this.access._id != undefined;
    },
    isMe() {
      return this.access && this.access.user == this.$auth.user()._id;
    },
    isManager() {
      return this.privilege == 2;
    },
    isCustom() {
      return this.privilege == 4;
    },
    mapped_items() {
      if (!this.isCustom) return [];
      return this.items.map((o) => {
        return { name: o };
      });
    },
    title() {
      if (this.access) return "Edit";
      else return "Add";
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid e-mail");
      !this.$v.email.required && errors.push("E-mail is required");
      return errors;
    },
    privilegeErrors() {
      const errors = [];
      if (!this.$v.privilege.$dirty) return errors;
      !this.$v.privilege.required && errors.push("Role is required");
      return errors;
    },
  },
  mounted() {
    this.loadCountryState();
    EventBus.$on("edit-corp-access", this.open);
  },
  destroyed() {
    EventBus.$off("edit-corp-access", this.open);
  },
  methods: {
    ...mapActions(["addCorpAccess", "updateCorpAccess"]),
    open(data) {
      this.access = data;
      if (data) {
        this.email = data.email;
        this.name = data.name;
        this.phone = data.phone;
        this.privilege = data.privilege;
        this.items = _.pluck(data.items, "name");
        this.access_wage = data.access_wage;
        this.country_states = data.country_states;
      } else {
        this.email = "";
        this.name = "";
        this.phone = "";
        this.privilege = null;
        this.items = [];
        this.country_states = [];
        this.access_wage = false;
      }
      this.dialog = true;
      this.$v.$reset();
    },
    submit() {
      if (!this.email || !this.privilege) {
        return this.$toast.error(
          "Please enter email and select an access level"
        );
      }
      if (this.access && this.access._id) this.update();
      else this.create();
    },
    async update() {
      const phone = this.phone || "";
      const params = {
        criteria: { _id: this.access._id },
        action: {
          $set: {
            name: this.name,
            phone: phone,
            privilege: this.privilege,
            access_wage: this.access_wage,
            items: this.mapped_items,
            country_states: this.country_states,
          },
        },
      };
      this.loading = true;
      try {
        const result = await this.$api.corp.access.update(params);
        this.updateCorpAccess(result);
        this.dialog = false;
      } catch (err) {
        this.$toast.error(err.response?.data);
      }
      this.loading = false;
    },
    async create() {
      const phone = this.phone || "";
      const params = {
        email: this.email,
        name: this.name,
        phone: phone,
        privilege: this.privilege,
        items: this.mapped_items,
        country_states: this.country_states,
      };
      this.loading = true;
      try {
        const result = await this.$api.corp.access.create(params);
        this.addCorpAccess(result);
        this.dialog = false;
      } catch (err) {
        this.$toast.error(err.response?.data);
      }
      this.loading = false;
    },
    async loadCountryState() {
      const cities = await this.$api.city.list({
        criteria: {},
        select: "country state",
      });
      this.country_state_items = cities.map((o) => {
        return `${o.country}_${o.state}`;
      });
      this.country_state_items = [...new Set(this.country_state_items)].sort();
    },
  },
};
</script>