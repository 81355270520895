<template lang="pug">
div
  Table
  SharePayments.my-3
  CombineMenus.my-3
</template>

<script>
import Table from "./Table/index";
import SharePayments from "./SharePayments";
import CombineMenus from "./CombineMenus";
import { mapActions } from "vuex";

export default {
  components: { Table, SharePayments, CombineMenus },
  mounted() {
    this.load();
  },
  destroyed() {
    this.setBizAccounts([]);
  },
  methods: {
    ...mapActions(["setBizAccounts"]),
    async load() {
      const params = {
        criteria: {
          $and: [
            { "payments.managed_account": { $exists: true, $ne: "" } },
            { "payments.managed_account": { $ne: null } },
          ],
        },
        select:
          "name short_name status orderType tax_items online_start pos_start created address industry geo_service_zone url",
      };
      const result = await this.$api.biz.list(params);
      this.setBizAccounts(result);
    },
  },
};
</script>
