<template lang="pug">
v-dialog(v-model="dialog", width="500")
  v-form(@submit.prevent="handleSave")
    v-card(v-if="item")
      v-toolbar(flat, dense)
        .subtitle-2 {{ title }} Service Subscription
      v-divider
      v-card-text
        BizSelector(@select="selectBiz", v-if="!item._id")
        .subtitle-2 {{ item.biz_name }}
        v-row(dense)
          v-col(cols="8")
            v-select(
              v-model="item.service_item",
              :items="service_items",
              item-text="name",
              item-value="_id"
            )
          v-col(cols="4")
            v-select(
              label="Quantity",
              v-model="item.quantity",
              :items="quantity_items"
            )
        v-switch(label="Status", v-model="item.status", color="secondary")
      v-card-actions
        v-btn(color="secondary", type="submit", block, :loading="loading") Save
      Delete(:item="item", @deleted="dialog = false")
</template>

<script>
import { EventBus } from "@/event-bus";
import { mapActions, mapGetters } from "vuex";
import _ from "underscore";
import Delete from "./Delete";

export default {
  components: { Delete },
  data() {
    return {
      dialog: false,
      item: null,
      loading: false,
      quantity_items: _.range(1, 10),
      confirmDelete: false,
    };
  },
  computed: {
    ...mapGetters(["service_items"]),
    title() {
      return this.item?._id ? "Edit" : "Create";
    },
  },
  mounted() {
    EventBus.$on("edit-service-subscription", this.open);
  },
  destroyed() {
    EventBus.$off("edit-service-subscription", this.open);
  },
  methods: {
    ...mapActions([
      "addBizServiceSubscription",
      "updateBizServiceSubscription",
    ]),
    open(data) {
      if (data) {
        this.item = JSON.parse(JSON.stringify(data));
      } else {
        this.item = {
          biz: "",
          biz_name: "",
          service_item: "",
          quantity: 1,
          status: true,
        };
      }
      this.confirmDelete = false;
      this.dialog = true;
    },
    selectBiz(biz) {
      this.item.biz = biz._id;
      this.item.biz_name = biz.name;
    },
    handleSave() {
      if (!this.item) return;
      if (this.item._id) this.update();
      else this.create();
    },
    async create() {
      if (!this.item || this.item._id) return;
      this.loading = true;
      try {
        const result = await this.$api.service.subscription.create(this.item);
        this.addBizServiceSubscription(result);
        this.dialog = false;
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
      this.loading = false;
    },
    async update() {
      if (!this.item || !this.item._id) return;
      const { service_item, quantity, status } = this.item;
      const params = {
        item_id: this.item._id,
        action: { $set: { service_item, quantity, status } },
      };
      this.loading = true;
      try {
        const result = await this.$api.service.subscription.update(params);
        this.updateBizServiceSubscription(result);
        this.dialog = false;
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
      this.loading = false;
    },
  },
};
</script>