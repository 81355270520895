<template lang="pug">
div
  v-text-field(v-model="search", label="Search", dense, clearable)
  Block.my-3(
    v-for="(group, index) in groups",
    :key="index",
    :title="group.title",
    :items="group.items",
    :search="search"
  )
</template>

<script>
import { mapGetters } from "vuex";
import _ from "underscore";
import Block from "./Block";

export default {
  components: { Block },
  data() {
    return { search: "" };
  },
  computed: {
    ...mapGetters(["service_subscriptions", "service_items"]),
    groups() {
      return _.chain(this.service_subscriptions)
        .sortBy("biz_name")
        .groupBy("service_item")
        .map((items, key) => {
          const title = this.getServiceName(key);
          return { title, items };
        })
        .sortBy("title")
        .value();
    },
  },
  methods: {
    getServiceName(id) {
      return this.service_items.find((item) => item._id === id)?.name;
    },
  },
};
</script>