<template lang="pug">
.d-flex.flex-row
  v-btn(@click="download()", color="secondary", outlined, rounded, small)
    span PDF
    v-icon(right, small) mdi-printer
</template>

<script>
import jsPDF from "jspdf";
import _ from "underscore";

export default {
  props: ["order", "delivOrder"],
  computed: {
    service_fee_value() {
      if (!this.order) return 0;
      const service_fee = _.find(
        this.order.payment.selflane.items,
        (o) => o.name == "service_fee"
      );
      if (service_fee) return service_fee.value;
      return 0;
    },
    taxNService() {
      if (!this.order) return 0;
      return this.order.payment.tax + this.service_fee_value;
    },
    deliveryFee() {
      if (!this.order) return 0;
      const found = _.find(
        this.order.payment.selflane.items,
        (o) => o.name == "delivery_fee"
      );
      if (found) return found.value;
      return this.order.payment.fee;
    },
    distanceSurcharge() {
      if (!this.order) return 0;
      const found = _.find(
        this.order.payment.selflane.items,
        (o) => o.name == "distance_surcharge"
      );
      if (found) return found.value;
      return 0;
    },
    tip() {
      if (!this.order) return 0;
      const found = _.find(
        this.order.payment.selflane.items,
        (o) => o.name == "delivery_tip"
      );
      if (found) return found.value;
      return this.order.payment.tip;
    },
  },
  methods: {
    download() {
      if (!this.order) return;
      const currency = this.$options.filters.currency;
      const phone = this.$options.filters.phone;
      const address = this.$options.filters.address;
      const datetime = this.$options.filters.datetime;
      const time = this.$options.filters.time;
      const modifiers = this.$options.filters.modifiers;

      let doc = new jsPDF({ format: "letter" });
      let x = 19;
      let y = 25;
      let pageHeight = doc.internal.pageSize.height;

      addTitle(this.order);
      addLine();
      addCustomer(this.order);
      addLine();
      addItems(this.order);

      // add payments
      addTableLine("Subtotal", currency(this.order.payment.dish));
      if (this.service_fee_value == 0) {
        addTableLine("Tax", currency(this.taxNService));
      } else {
        addTableLine("Tax and fees", currency(this.taxNService));
      }
      if (this.order.payment.gift > 0) {
        addTableLine("Gift", currency(this.order.payment.gift));
      }
      if (this.deliveryFee > 0) {
        addTableLine("Delivery Fee", currency(this.deliveryFee));
      }
      if (this.distanceSurcharge > 0) {
        addTableLine("Distance Surcharge", currency(this.distanceSurcharge));
      }
      if (this.tip > 0) {
        addTableLine("Tip", currency(this.tip));
      }
      _.each(this.order.payment.deduction.items, (o) => {
        addTableLine(o.name, currency(-o.value));
      });
      addTableLine("Total", currency(this.order.payment.total));
      nextLine(4);
      addLine();
      // add charge
      addTableLine("Payments", "");
      _.each(this.order.splitChecks, (check) => {
        if (check.payment.charge && check.payment.charge.amount > 0) {
          addTableLine(
            "Online" + " ..." + check.payment.card.last4,
            currency(check.payment.charge.amount)
          );
        }
        _.each(check.payment.charges, (charge) => {
          addTableLine(
            charge.method + "..." + charge.last4,
            currency(charge.amount)
          );
        });
      });
      if (this.order.payment.charge && this.order.payment.charge.amount > 0) {
        addTableLine(
          "Online" + " ..." + this.order.payment.card.last4,
          currency(this.order.payment.charge.amount)
        );
      }
      _.each(this.order.payment.charges, (charge) => {
        addTableLine(
          charge.method + ": ..." + charge.last4,
          currency(charge.amount)
        );
      });
      addLine();
      if (
        this.order.status >= 0 &&
        this.delivOrder &&
        this.delivOrder.driver &&
        this.delivOrder.pickupTime &&
        this.delivOrder.deliverTime
      ) {
        addTableLine("Driver", this.delivOrder.driver.name);
        addTableLine("Pick-up time", time(this.delivOrder.pickupTime));
        addTableLine("Deliver time", time(this.delivOrder.deliverTime));
        addLine();
      }
      addTableLine("Order was created at " + datetime(this.order.created), "");

      doc.save("order_receipt.pdf");

      function addTitle(order) {
        doc.setFontSize(12);
        if (order.status < 0) {
          doc.text("This order is canceled.", x, y);
          nextLine(4);
          addLine();
        }
        doc.text(order.seller.name, x, y);
        doc.setFontSize(10);
        nextLine();
        doc.text(phone(order.seller.phone), x, y);
        nextLine();
        doc.text(address(order.seller.address), x, y);
        nextLine(4);
      }

      function addCustomer(order) {
        doc.text("#" + order.orderNumber, x, y);
        nextLine();
        doc.text(datetime(order.needed), x, y);
        nextLine();
        doc.text(order.customer.name + " " + phone(order.customer.phone), x, y);
        if (order.type == "delivery") {
          nextLine();
          doc.text(address(order.customer.address), x, y);
          if (order.customer.address && order.customer.address.note) {
            nextLine();
            doc.text(order.customer.address.note, x, y);
          }
        }
        nextLine(4);
      }

      function addItems(order) {
        _.each(order.dishes, (o) => {
          addTableLine(o.name, currency(o.unitPrice) + "X" + o.quantity);
          const mod = modifiers(o.modifiers);
          if (mod && mod.length) {
            addTableLine(mod, "");
          }
        });
        _.each(order.gift, (o) => {
          addTableLine("Gift Card", currency(o.amount));
        });
        _.each(order.vouchers, (o) => {
          addTableLine(o.name, currency(-o.amount));
        });
        nextLine(4);
      }

      function addTableLine(left, right) {
        doc.text(left, x, y);
        nextCell(150);
        doc.text(right, x, y);
        nextLine(6);
      }

      function addLine(height) {
        doc.line(x, y, x + 180, y);
        nextLine(height);
      }
      function nextLine(height) {
        x = 19;
        if (y + 25 > pageHeight) {
          doc.addPage();
          y = 25;
          return;
        }
        if (height) y += height;
        else y += 8;
        x = 19;
      }
      function nextCell(width) {
        if (width) x += width;
        else x += 40;
      }
    },
  },
};
</script>