<template lang="pug">
v-list-item
  v-list-item-content
    v-list-item-title
      span Loyalty Program
  v-list-item-action
    v-btn-toggle(v-model="value", @change="submit", dense, color="secondary")
      v-btn(
        v-for="(item, index) in items",
        :key="index",
        x-small,
        :value="item.value"
      ) {{ item.name }}
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    item: { type: Object, required: true },
  },
  data() {
    return {
      loading: false,
      value: this.item.member || 0,
      items: [
        { name: "Inactive", value: 0 },
        { name: "Basic", value: 1 },
        { name: "Advanced", value: 2 },
      ],
    };
  },
  methods: {
    ...mapActions(["setBizService"]),
    async submit() {
      this.loading = true;
      const criteria = { _id: this.item._id };
      const action = { $set: { member: this.value } };
      try {
        const result = await this.$api.service.account.update({
          criteria,
          action,
        });
        this.setBizService(result);
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
      this.loading = false;
    },
  },
};
</script>