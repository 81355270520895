<template lang="pug">
div
  StatsGrowth(:api="api", :title="title", @select="select")
  GrowthByBiz.my-10(:result="growthByBiz")
</template>

<script>
import GrowthByBiz from "./GrowthByBiz.vue";

export default {
  components: { GrowthByBiz },
  data() {
    return {
      api: "/orders/messaging/stats/growth",
      title: "Messaging Growth",
      year: null,
      growthByBiz: [],
    };
  },
  methods: {
    select(year) {
      this.year = year;
      this.loadBiz();
    },
    // load growth by biz
    async loadBiz() {
      if (!this.year) return;
      const params = { year: this.year };
      try {
        const { data } = await this.axios.post(
          "/orders/messaging/stats/growth/biz",
          params
        );
        this.growthByBiz = data;
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
    },
  },
};
</script>
