<template lang="pug">
div
  v-btn(@click="open", small, rounded, outlined, color="secondary") Early Fraud
  v-dialog(v-model="dialog", width="500")
    v-card(v-if="order")
      v-toolbar(dense, flat)
        .subtitle-2 Email on Early Fraud Warning
      v-card-text
        OrderHeader(:order="order")
        v-textarea(v-model="content", label="Content")
      v-card-actions
        v-btn(@click="send()", block, color="secondary", :loading="loading") Send
</template>

<script>
import moment from "moment-timezone";
export default {
  props: ["order"],
  data() {
    return {
      dialog: false,
      loading: false,
      content: "",
    };
  },
  methods: {
    open() {
      this.setContent();
      this.dialog = true;
    },
    async setContent() {
      this.content =
        "Hi there, we have received an early fraud warning on a payment you made. The payment was for the order you placed at " +
        this.order.seller.name +
        " on " +
        moment(this.order.needed).format("M/D/YY") +
        ". The order number was #" +
        this.order.orderNumber +
        ". Please let us know if there was any issue with this order.";
    },
    async send() {
      this.loading = true;
      let params = {
        email: this.order.customer.email,
        subject: "Early Fraud Warning on Your Payment",
        message: this.content,
      };
      try {
        await this.axios.post("/misc/simpleEmail", params);
        this.dialog = false;
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
      this.loading = false;
    },
  },
};
</script>
