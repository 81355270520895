<template lang="pug">
v-dialog(v-model="dialog", width="300")
  template(v-slot:activator="{ on }")
    v-btn.mr-3(@click="open()", small, depressed, color="error") Send Test Order
  v-card(v-if="biz")
    v-toolbar(flat, dense)
      .subtitle-2 {{ biz.name }}
    v-divider
    v-card-text
      span Do you want to send a test online order to this business?
      v-row
        v-col
          v-btn(block, text, @click="dialog = false") Cancel
        v-col
          v-btn(block, color="secondary", :loading="loading", @click="send()") Send
</template>

<script>
export default {
  props: ["biz"],
  data() {
    return {
      dialog: false,
      loading: false,
    };
  },
  methods: {
    open() {
      if (!this.biz) return;
      this.dialog = true;
    },
    async send() {
      if (!this.biz) return;
      this.loading = true;
      const params = { bizId: this.biz._id };
      try {
        await this.$api.order.custom("createTest", params);
        this.$toast.success("Test order sent");
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
