<template lang="pug">
div(v-if="biz")
  v-card(flat)
    v-toolbar(flat, dense)
      .subtitle-2 {{ biz._id }}
      v-spacer
      .subtitle-2 {{ biz.created | date }}
      v-btn.ml-2(icon, small, @click="close")
        v-icon(small) close
    v-divider
    v-card-text
      div
        v-btn.mr-3(@click="handleDelete", color="error", depressed, small) Delete
          v-icon(small, right) mdi-delete
        //- PullFund(:biz="biz")
        CreateTest(:biz="biz")
        TouchService(:biz="biz")
        v-btn.mr-3(color="secondary", @click="showThirtyDay()", small) Thirty Day Sales
        Actions
    simple-table
      tbody
        tr
          th Name
          td
            div {{ biz.name }}
            .caption.text--secondary(v-if="biz.short_name") {{ biz.short_name }}
          td.text-right
            EditName(:biz="biz")
        tr
          th Place ID
          td
            ClickToCopy(:text="biz.place_id", v-if="biz.place_id")
          td.text-right
            a(v-if="biz.place_id", :href="google_link", target="_blank") Open
        tr
          th URL
          td {{ biz.url }}
            v-btn(
              icon,
              x-small,
              :href="'https://selflane.com/bizs/' + biz.url",
              target="_blank",
              color="secondary"
            )
              v-icon mdi-link
          td.text-right
            EditURL(:biz="biz")
        tr
          th Public
          td {{ biz.status?.byAdmin ? "Yes" : "No" }}
          td.text-right
            v-btn(icon, @click="togglePublic()", small, color="secondary")
              v-icon(small) mdi-sync
        tr(v-if="biz.payments?.managed_account")
          th Payout Account
          td
            StripeAccount(:account="biz.payments.managed_account")
            span.mx-2 {{ biz.payments.statement_descriptor }}
            v-btn(
              v-if="biz.payments.managed_account",
              icon,
              x-small,
              :href="'https://dashboard.stripe.com/connect/accounts/' + biz.payments.managed_account",
              target="_blank",
              color="secondary"
            )
              v-icon mdi-link
          td
            .d-flex.flex-row.justify-end
              StatementDescriptor(:biz="biz")
              DeleteManagedAccount(:biz="biz")
        tr(v-if="biz.payments?.customer")
          th Payment Account
          td {{ biz.payments.customer }}
            v-btn(
              v-if="biz.payments.customer",
              icon,
              x-small,
              :href="'https://dashboard.stripe.com/customers/' + biz.payments.customer",
              target="_blank",
              color="secondary"
            )
              v-icon mdi-link
          td
        tr(v-if="biz.orderType")
          th Order Types
          td
            v-btn.mr-2(
              x-small,
              rounded,
              outlined,
              @click="handleEditOrderType(biz)"
            )
              span.mx-1(v-if="biz.orderType.pickup.status")
                v-icon.fa.fa-shopping-basket.mr-1(x-small)
                span {{ biz.orderType.pickup.beforehand }}
              span.mx-1(v-if="biz.orderType.delivery.status")
                v-icon.fa.fa-truck.mr-1(
                  x-small,
                  :color="biz.orderType.delivery.deliv ? 'success' : ''"
                )
                span {{ biz.orderType.delivery.beforehand }}
          td
        tr
          th Zone
          td
            span(v-if="biz.geo_service_zone") {{ biz.geo_service_zone.name }}
          td.text-right
            Zone(:biz="biz")
  .s-vstack
    BizLogo
    BizBasic(:biz="biz")
    BizTaxRates
    BizEmail
    BizPhone
    BizAddress
    Hours(:biz="biz", @updated="setBiz($event)")
    Access(ref="access", :showNotice="false")
    Devices(ref="devices")
    OrderType
    ThirtyDay
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import EditName from "./Edit/Name";
import StatementDescriptor from "@/components/Business/Stripe/StatementDescriptor";
import EditURL from "./Edit/URL";
import TouchService from "./TouchService";
import PullFund from "./PullFund";
import CreateTest from "./CreateTest";
import OrderType from "./Edit/OrderType";
import Zone from "./Edit/Zone";
import Hours from "/libs/components/Biz/Hours";
import Access from "/libs/components/Biz/Access";
import ThirtyDay from "./ThirtyDay";
import DeleteManagedAccount from "./DeleteManagedAccount";
import Devices from "/libs/components/POS/Devices";
import { EventBus } from "@/event-bus.js";
import StripeAccount from "./StripeAccount/index";
import Actions from "./Actions";

export default {
  components: {
    EditName,
    StatementDescriptor,
    EditURL,
    TouchService,
    PullFund,
    CreateTest,
    OrderType,
    Zone,
    Hours,
    Access,
    ThirtyDay,
    DeleteManagedAccount,
    Devices,
    StripeAccount,
    Actions,
  },
  computed: {
    ...mapGetters(["biz"]),
    phone() {
      return this.biz?.phones?.[0]?.number;
    },
    google_link() {
      return `https://www.google.com/maps/place/?q=place_id:${this.biz?.place_id}`;
    },
  },
  mounted() {
    EventBus.$on("show-biz-detail", this.loadBiz);
  },
  destroyed() {
    EventBus.$off("show-biz-detail", this.loadBiz);
    this.setBiz(null);
  },
  methods: {
    ...mapActions(["setBiz", "removeBizAccount"]),
    async loadBiz(bizId) {
      if (!bizId) return;
      const params = {
        criteria: { _id: bizId },
        select: "-dishes -tables",
      };
      const result = await this.$api.biz.retrieve(params);
      this.setBiz(result);
      await this.wait(200);
      this.$refs.access.load();
      this.$refs.devices.load();
    },
    async wait(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async togglePublic() {
      if (!this.biz) return;
      let status = 1;
      if (this.biz.status.byAdmin) status = 0;
      const params = {
        criteria: { _id: this.biz._id },
        action: { $set: { "status.byAdmin": status } },
      };
      const result = await this.$api.biz.update(params);
      this.setBiz(result);
    },
    handleDelete() {
      confirm("Do you really want to delete this business?") &&
        this.deleteOne();
    },
    async deleteOne() {
      if (!this.biz) return;
      const params = { id: this.biz._id };
      try {
        const result = await this.$api.biz.delete(params);
        this.removeBizAccount(result);
        this.setBiz();
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
    },
    handleEditOrderType(item) {
      EventBus.$emit("edit-biz-ordertype", item);
    },
    showThirtyDay() {
      if (!this.biz) return;
      EventBus.$emit("show-thirty-day", this.biz._id);
    },
    close() {
      this.setBiz();
    },
  },
};
</script>
